




















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetExportDataTable,
  GetUserDataTable2,
  PostMiaoshuxingfenxi,
  PostXiangguanxingfenxi,
  PostGuanlianguizefenxi,
  PostChayiduli,
  PostJiapeidui,
  PostSaveResult,
} from "@/request/analysis";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import SaveDialog from "../../components/saveDialog.vue";
import DataTableReview from "../../components/data-table-review.vue";
import ZhibiaoSelect from "./zhibiao-select.vue";
import ReportTransfer from "./report-transfer.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    SaveDialog,
    DataTableReview,
    ZhibiaoSelect,
    ReportTransfer,
  },
})
export default class Name extends Vue {
  private config: any = {
    // project_id: "",
    table_id: "",
    way: "描述性分析",
  };
  private projects: any[] = [];
  private tables: any[] = [];
  private ways: any[] = [
    {
      name: "描述性分析",
      explain:
        "通过数据频数、集中趋势、离散趋势等描述数据分布，绘制折线图，箱式图等统计图形",
    },
    {
      name: "相关性分析",
      explain:
        "两个连续变量之间的相关性评估，支持Pearson、Spearman等相关分析方法。",
    },
    {
      name: "差异性分析",
      explain:
        "比较不同样本组间的差异，支持常见的差异性分析方法，如t检验、矫正t检验、秩和检验、单因素方差分析、Pearson卡方检验、Fisher精确概率法等，根据数据特征推荐最适用的检验方法。",
    },
    {
      name: "关联规则分析",
      explain: "关联规则是数据挖掘中的概念，通过分析数据，找到数据之间的关联。",
    },
    {
      name: "聚类分析",
      explain: "根据物以类聚的原理将相似的样品（或变量）归为一类。",
    },
    {
      name: "回归分析",
      explain: "分析多个因素对因变量的影响程度，支持逻辑回归、线性回归。",
    },
  ];
  private results: any[] = [];
  private cResult: any = {};
  private ifShowSaveDialog: boolean = false;
  private ifShowDataTableReview: boolean = false;
  /**
   * @description 初始化配置
   */
  private initConfig() {
    const config = {
      // project_id: this.config.project_id,
      table_id: this.config.table_id,
      way: this.config.way,
    };
    this.config = config;
  }
  // /**
  //  * @description 获得可选项目
  //  */
  // private fetchProjects() {
  //   return new Promise((resolve, reject) => {
  //     GetUserProjects(this).then((data: any) => {
  //       this.projects = data;
  //       if (this.projects.length > 0) {
  //         this.config.project_id = this.projects[0]["project_id"];
  //         resolve();
  //       } else {
  //         this.$message.warning("请先创建一个项目");
  //         reject();
  //       }
  //     });
  //   });
  // }
  /**
   * @description 处理项目变化
   */
  private projectIdChange() {
    this.fetchTableData();
    (this.$refs.zhibiaoSelect as any).init();
  }
  /**
   * @description 获得数据表格
   */
  private fetchTableData() {
    this.config.table_id = "";
    GetUserDataTable2(this).then((data: any) => {
      this.tables = data;
    });
  }
  /**
   * @description 选择的数据变化后
   */
  private tableIdChange() {
    (this.$refs.zhibiaoSelect as any).init();
  }
  /**
   * @description 选择统计方法
   */
  private chooseWay(way: any) {
    this.config.way = way;
    this.config.table_id = "";
    this.initConfig();
  }
  /**
   * @description 开始分析
   */
  private startAnalysis() {
    if (!this.config.table_id) {
      this.$message.warning("请选择一个数据表格！");
      return;
    }
    switch (this.config.way) {
      case "描述性分析":
        this.miaoshuxingFenxi();
        break;
      case "相关性分析":
        this.xiangguanxingFenxi();
        break;
      case "关联规则分析":
        this.guanlianguizeFenxi();
        break;
      case "差异性分析":
        this.chayijianyan();
        break;
    }
  }
  /**
   * @description 描述性分析
   */
  private miaoshuxingFenxi() {
    if (!this.config["分组变量"]["id"] && !this.config["分析变量"]["id"]) {
      this.$message.warning("分组变量或分析变量至少选择其中一个");
      return;
    }
    if (this.config["分组变量"]["id"] === this.config["分析变量"]["id"]) {
      this.$message.warning("分组变量和分析变量不能相同");
      return;
    }
    PostMiaoshuxingfenxi(this, this.config).then((data: any) => {
      const result: any = {
        title: "描述性分析",
        report: data,
        _id: new Date().getTime(),
      };
      if (this.config["分组变量"]["id"]) {
        if (this.config["分析变量"]["type"] === "连续变量") {
          result.type = "有分组连续";
        } else if (this.config["分析变量"]["type"] === "分类变量") {
          result.type = "有分组分类";
        }
      } else {
        if (this.config["分析变量"]["type"] === "连续变量") {
          result.type = "无分组连续";
        } else if (this.config["分析变量"]["type"] === "分类变量") {
          result.type = "无分组分类";
        }
      }
      this.results.push(result);
      this.cResult = this.results[this.results.length - 1];
    });
  }
  /**
   * @description 相关性分析
   */
  private xiangguanxingFenxi() {
    if (!this.config["x变量"]["id"] || !this.config["y变量"]["id"]) {
      this.$message.warning("x变量和y变量必选");
      return;
    }
    if (this.config["x变量"]["id"] === this.config["y变量"]["id"]) {
      this.$message.warning("x变量和y变量不能相同");
      return;
    }
    PostXiangguanxingfenxi(this, this.config).then((data: any) => {
      const result: any = {
        title: "相关性分析",
        report: data,
        _id: new Date().getTime(),
      };
      this.results.push(result);
      this.cResult = this.results[this.results.length - 1];
    });
  }
  /**
   * @description 关联规则分析
   */
  private guanlianguizeFenxi() {
    const xLength = this.config["X变量"].length;
    if (xLength === 0 || (xLength === 1 && !this.config["X变量"][0]["id"])) {
      this.$message.warning("请至少选择一个X变量");
      return;
    }
    if (!this.config["Y变量"]["id"]) {
      this.$message.warning("请选择一个Y变量");
      return;
    }
    PostGuanlianguizefenxi(this, this.config).then((data: any) => {
      const result: any = {
        title: "关联规则分析",
        report: data,
        _id: new Date().getTime(),
      };
      result.report["支持度"] = this.config["支持度"];
      result.report["置信度"] = this.config["置信度"];
      this.results.push(result);
      this.cResult = this.results[this.results.length - 1];
    });
  }
  /**
   * @description 差异性分析
   */
  private chayijianyan() {
    if (this.config["样本方法"] === "独立样本") {
      if (!this.config["分组变量"]["id"] || !this.config["分析变量"]["id"]) {
        this.$message.warning("分组变量或分析变量都必须填写");
        return;
      }
      if (this.config["分组变量"]["id"] === this.config["分析变量"]["id"]) {
        this.$message.warning("分组变量和分析变量不能相同");
        return;
      }
      PostChayiduli(this, this.config).then((data: any) => {
        const result: any = {
          title: "差异性分析",
          report: data,
          _id: new Date().getTime(),
        };
        if (this.config["分析变量"]["type"] === "连续变量") {
          result.type = "独立连续";
        } else if (this.config["分析变量"]["type"] === "分类变量") {
          result.type = "独立分类";
        }
        this.results.push(result);
        this.cResult = this.results[this.results.length - 1];
      });
    } else if (this.config["样本方法"] === "配对样本") {
      if (
        !this.config["分析变量"]["interview_id"] ||
        !this.config["配对分析变量"]["interview_id"]
      ) {
        this.$message.warning("分析变量的访视和配对分析变量的访视都必须选择");
        return;
      }
      if (
        !this.config["分析变量"]["分析变量"]["id"] ||
        !this.config["配对分析变量"]["分析变量"]["id"]
      ) {
        this.$message.warning("分析变量和配对分析变量都必须选择");
        return;
      }
      if (this.config["分析变量"]["分析变量"]["type"] === "分类变量") {
        if (
          this.config["分析变量"]["分析变量"]["title"] !==
          this.config["配对分析变量"]["分析变量"]["title"]
        ) {
          this.$message.warning("分析变量和配对分析变量必须相同");
          return;
        }
        if (
          this.config["分析变量"]["分析变量"]["value"] !==
          this.config["配对分析变量"]["分析变量"]["value"]
        ) {
          this.$message.warning("分析变量和配对分析变量的选项值必须相同");
          return;
        }
      }

      PostJiapeidui(this, this.config).then((data: any) => {
        const result: any = {
          title: "差异性分析",
          report: data,
          _id: new Date().getTime(),
        };
        if (this.config["分析变量"]["分析变量"]["type"] === "连续变量") {
          result.type = "配对连续";
        } else if (this.config["分析变量"]["分析变量"]["type"] === "分类变量") {
          result.type = "配对分类";
        }
        this.results.push(result);
        this.cResult = this.results[this.results.length - 1];
      });
    }
  }
  /**
   * @description 改变当前的报告
   */
  private changeResult(result: any) {
    this.cResult = result;
  }
  /**
   * @description 删除一份报告
   */
  private deleteOneResult(index: number, result: any) {
    if (result === this.cResult) {
      this.results.splice(index, 1);
      if (this.results.length > 0) {
        this.cResult = this.results[0];
      }
    } else {
      this.results.splice(index, 1);
    }
  }
  /**
   * @description 打开保存弹窗
   */
  private openSaveDialog() {
    this.ifShowSaveDialog = true;
  }
  /**
   * @description 保存报告
   */
  private saveResult() {
    const params: any = {
      // project_id: this.config.project_id,
      table_id: this.config.table_id,
      报告名称: this.cResult["报告名称"],
      分类: "自定义分析",
      data: this.cResult,
    };
    PostSaveResult(this, params).then((data: any) => {
      this.$message.success("保存成功");
      this.ifShowSaveDialog = false;
    });
  }
  /**
   * @description 导出数据
   */
  private exportData(title: any, name: any) {
    if (title === "关联规则") {
      const params: any = this.config;
      params.is_download = true;
      params.download_name = name;
      PostGuanlianguizefenxi(this, params).then((data: any) => {
        GetExportDataTable(data.file_name);
      });
    }
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.config.way = this.$route.query.type;
    if (this.$route.query) {
      if (this.$route.query.way) {
        this.chooseWay(this.$route.query.way);
      }
    }
    this.fetchTableData();
    // this.fetchProjects().then(() => {
    //   this.fetchTableData();
    // });
  }
}
