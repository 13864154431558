





















































































import { Component, Vue, Prop } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Xiangguanxingfenxi extends Vue {
  @Prop()
  private report: any;
  private scatterOption: any = {
    yAxis: {},
    xAxis: {},
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "scatter",
      },
    ],
  };
  private loaded: boolean = false;
  private reportChange() {
    this.$emit("update:report", this.report);
  }
  private get ZongbiaoHeader() {
    return this.report["统计描述总表"]["name"];
  }
  private get ZhengtaixingHeader() {
    const arr = this.report["正态性检验"]["name"];
    arr[0] = this.report["分组变量"];
    return arr;
  }
  /**
   * @description 创建散点图
   */
  private createScatter() {
    this.scatterOption.series[0].data = this.report["散点图"];
    this.loaded = true;
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.createScatter();
  }
}
