




















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class MiaoshuFenzuLianxu extends Vue {
  @Prop()
  private report: any;
  private boxOption: any = {
    yAxis: {
      type: "value",
    },
    xAxis: {
      type: "category",
    },
    tooltip: {
      formatter: (param: any) => {
        return [
          param.name + ": ",
          "最小值：" + param.data[1],
          "下四分位数：" + param.data[2],
          "中位数：" + param.data[3],
          "上四分位数：" + param.data[4],
          "最大值：" + param.data[5],
        ].join("<br/>");
      },
    },
    series: [
      {
        type: "boxplot",
      },
    ],
  };
  private loaded: boolean = false;
  @Watch("report", { immediate: true })
  private reportChange() {
    this.createBoxPlot();
    this.$emit("update:report", this.report);
  }
  private get ZongbiaoHeader() {
    return this.report["统计描述总表"]["name"];
  }
  private get ZongbiaoData() {
    const headerArr: any = this.report["统计描述总表"]["name"];
    headerArr[0] = this.report["分析变量"];
    const bodyArr: any = this.report["统计描述总表"]["data"];
    const data: any = [headerArr, bodyArr];
    return data;
  }
  private get ZhengtaixingHeader() {
    const arr = this.report["正态性检验"]["name"];
    arr[0] = this.report["分组变量"];
    return arr;
  }
  /**
   * @description 控制表格合并
   */
  private spanMethod({ row, column, rowIndex, columnIndex }: any) {
    if (rowIndex === 0 && columnIndex === 0) {
      return [2, 1];
    }
    if (rowIndex === 1 && columnIndex === 0) {
      return [0, 0];
    }
  }
  private createCellClass({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex !== 0 && rowIndex === 0) {
      return "blueCell";
    } else {
      return "whiteCell";
    }
  }
  /**
   * @description 创建盒须图
   */
  private createBoxPlot() {
    this.boxOption.series[0].data = this.report["箱线图"].data;
    this.boxOption.xAxis.data = this.report["箱线图"].name;
    this.loaded = true;
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.createBoxPlot();
  }
}
