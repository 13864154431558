


















































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Guanlianguize extends Vue {
  @Prop()
  private report: any;
  @Watch("report", { immediate: true })
  private reportChange() {
    this.createGraph();
  }
  private loaded: boolean = false;
  private graphOption: any = {};
  private get pincifenxiData() {
    const r = this.report["频次分析表"]["data"].slice(0, 10);
    return r;
  }
  // private reportChange() {
  //   this.$emit("update:report", this.report);
  // }
  /**
   * @description 柱状图
   */
  private createGraph() {
    this.graphOption = {
      series: [
        {
          type: "graph",
          layout: "circular",
          circular: {
            rotateLabel: true,
          },
          roam: true,
          label: {
            normal: {
              show: true,
              position: "right",
              formatter: "{b}",
            },
          },
          lineStyle: {
            normal: {
              color: "source",
              curveness: 0.3,
            },
          },
        },
      ],
    };
    this.loaded = false;
    this.graphOption.series[0].data = this.report["关联规则图"]["nodes"];
    this.graphOption.series[0].categories =
      this.report["关联规则图"]["categories"];
    this.graphOption.series[0].links = this.report["关联规则图"]["links"];
    this.loaded = true;
  }
  /**
   * @description 导出数据
   */
  private exportData(name: any) {
    this.$emit("download", name);
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.createGraph();
  }
}
