import { render, staticRenderFns } from "./chayi-peidui-fenlei.vue?vue&type=template&id=f2100506&"
import script from "./chayi-peidui-fenlei.vue?vue&type=script&lang=ts&"
export * from "./chayi-peidui-fenlei.vue?vue&type=script&lang=ts&"
import style0 from "./chayi-peidui-fenlei.vue?vue&type=style&index=0&id=f2100506&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports