





























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class MiaoshuFenzuFenlei extends Vue {
  @Prop()
  private report: any;
  private loaded: boolean = false;
  private cChartType: any = "bar";
  private barOption: any = {
    yAxis: {
      type: "value",
    },
    xAxis: {
      name: "",
      type: "category",
      data: [],
    },
    series: [],
  };
  private pies: any[] = [];
  @Watch("report", { immediate: true })
  private reportChange() {
    this.createPie();
    this.createBar();
    this.$emit("update:report", this.report);
  }
  /**
   * @description 柱状图
   */
  private createBar() {
    this.loaded = false;
    this.cChartType = "bar";
    this.report["柱状图"].data.map((item: any) => {
      item.type = "bar";
      item.barGap = 0;
      item.label = {
        normal: {
          show: true,
          formatter: "{a}",
        },
      };
    });
    this.barOption.series = this.report["柱状图"].data;
    this.barOption.xAxis.name = this.report["分组变量"];
    this.barOption.xAxis.data = this.report["柱状图"].name;
    this.loaded = true;
  }
  /**
   * @description 饼图
   */
  private createPie() {
    this.loaded = false;
    this.cChartType = "pie";
    const arr: any = [];
    this.report["饼图"].map((item: any) => {
      const o: any = {};
      o.title = {
        text: item.name,
      };
      o.series = [
        {
          type: "pie",
          data: item.value.data,
          label: {
            normal: {
              show: true,
              formatter: "{b}:{c}",
            },
          },
        },
      ];
      arr.push(o);
    });
    this.pies = arr;
    this.loaded = true;
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.createPie();
    this.createBar();
  }
}
