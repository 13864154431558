




































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class MiaoshuWufenzuFenlei extends Vue {
  @Prop()
  private report: any;
  private loaded: boolean = false;
  private cChartType: any = "bar";
  private barOption: any = {
    yAxis: {
      type: "value",
    },
    xAxis: {
      name: "",
      type: "category",
      data: [],
    },
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "bar",
      },
    ],
  };
  private pieOption: any = {
    series: [
      {
        type: "pie",
        label: {
          normal: {
            show: true,
            formatter: "{b}:{c}",
          },
        },
      },
    ],
  };
  @Watch("report", { immediate: true })
  private reportChange() {
    this.createPie();
    this.createBar();
    this.$emit("update:report", this.report);
  }
  /**
   * @description 柱状图
   */
  private createBar() {
    this.loaded = false;
    this.cChartType = "bar";
    this.barOption.series[0].data = this.report["柱状图"].data;
    this.barOption.xAxis.name = this.report["分析变量"];
    this.barOption.xAxis.data = this.report["柱状图"].name;
    this.loaded = true;
  }
  /**
   * @description 饼图
   */
  private createPie() {
    this.loaded = false;
    this.$nextTick().then(() => {
      this.cChartType = "pie";
      this.pieOption.series[0].data = this.report["饼图"].data;
      this.loaded = true;
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.createPie();
    this.createBar();
  }
}
