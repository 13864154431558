






















































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetMiaoshuxingfenxiZhibiao,
  GetXiangguanxingfenxiZhibiao,
  GetGuanlianguizefenxiZhibiao,
  GetChayijianyanDuliyangbenZhibiao,
  GetChayijianyanPeiduiyangbenZhibiao,
} from "@/request/analysis";
@Component({})
export default class ZhibiaoSelect extends Vue {
  @Prop()
  private config: any;
  private fenzus: any[] = [];
  private fenxis: any[] = [];
  private xs: any[] = [];
  private ys: any[] = [];
  private interviews: any[] = [];
  private peiduiInterviews: any[] = [];
  private peiduiFenxis: any[] = [];
  private configChange() {
    this.$emit("update:config", this.config);
  }
  /**
   * @description 获得描述性分析的指标
   */
  private fetchMiaoshuxingfenxiZhibiao() {
    if (!this.config.table_id) {
      return;
    }
    const params: any = {
      params: {
        table_id: this.config.table_id,
      },
    };
    GetMiaoshuxingfenxiZhibiao(this, params).then((data: any) => {
      this.fenzus = data["分组变量"];
      this.fenxis = data["分析变量"];
    });
  }
  /**
   * @description 获得相关性分析的指标
   */
  private fetchXiangguangxingfenxiZhibiao() {
    if (!this.config.table_id) {
      return;
    }
    const params: any = {
      params: {
        table_id: this.config.table_id,
      },
    };
    GetXiangguanxingfenxiZhibiao(this, params).then((data: any) => {
      this.xs = data["x变量"];
      this.ys = data["y变量"];
    });
  }
  /**
   * @description 获得关联规则分析的指标
   */
  private fetchGuanlianguizefenxiZhibiao() {
    if (!this.config.table_id) {
      return;
    }
    const params: any = {
      params: {
        table_id: this.config.table_id,
      },
    };
    GetGuanlianguizefenxiZhibiao(this, params).then((data: any) => {
      this.xs = data["X变量"];
      this.ys = data["Y变量"];
    });
  }
  /**
   * @description 增加X选择
   */
  private addX() {
    this.config["X变量"].push({});
  }
  /**
   * @description 获得差异性分析独立样本的指标
   */
  private fetchChayijianyanduliyangbenZhibiao() {
    if (!this.config.table_id) {
      return;
    }
    this.$set(this.config, "分组变量", {});
    this.$set(this.config, "分析变量", {});
    const params: any = {
      params: {
        table_id: this.config.table_id,
      },
    };
    GetChayijianyanDuliyangbenZhibiao(this, params).then((data: any) => {
      this.fenzus = data["分组变量"];
      this.fenxis = data["分析变量"];
    });
  }
  /**
   * @description 获得差异性分析配对样本的指标
   */
  private fetchChayijianpeiduiyangbenZhibiao() {
    if (!this.config.table_id) {
      return;
    }
    delete this.config["分组变量"];
    this.$set(this.config, "分析变量", {});
    this.$set(this.config, "配对分析变量", {});
    this.$nextTick().then(() => {
      const params: any = {
        params: {
          table_id: this.config.table_id,
        },
      };
      GetChayijianyanPeiduiyangbenZhibiao(this, params).then((data: any) => {
        this.interviews = JSON.parse(JSON.stringify(data["分析变量"]));
        this.peiduiInterviews = JSON.parse(JSON.stringify(data["分析变量"]));
      });
    });
  }
  /**
   * @description 差异性分析-配对样本-分析变量发生变化时
   */
  private chayiPeiduiFenxiBianliangChange(ev: any) {
    if (
      this.config["配对分析变量"]["分析变量"] &&
      this.config["配对分析变量"]["分析变量"]["type"] !== ev.type
    ) {
      this.config["配对分析变量"]["分析变量"] = {};
      this.config["分析变量"]["分析变量"] = ev;
      this.$forceUpdate();
    } else {
      this.config["分析变量"]["分析变量"] = ev;
      this.$forceUpdate();
    }
  }
  /**
   * @description 差异性分析-配对样本-配对分析变量发生变化时
   */
  private chayiPeiduiFenxiBianliangPeizuiChange(ev: any) {
    if (
      this.config["分析变量"]["分析变量"] &&
      this.config["分析变量"]["分析变量"]["type"] !== ev.type
    ) {
      this.config["分析变量"]["分析变量"] = {};
      this.config["配对分析变量"]["分析变量"] = ev;
      this.$forceUpdate();
    } else {
      this.config["配对分析变量"]["分析变量"] = ev;
      this.$forceUpdate();
    }
  }
  /**
   * @description 初始化
   */
  private init() {
    switch (this.config.way) {
      case "描述性分析":
        this.$set(this.config, "分组变量", {});
        this.$set(this.config, "分析变量", {});
        this.fetchMiaoshuxingfenxiZhibiao();
        break;
      case "相关性分析":
        this.$set(this.config, "y变量", {});
        this.$set(this.config, "x变量", {});
        this.$set(this.config, "校验水准", 0.05);
        this.fetchXiangguangxingfenxiZhibiao();
        break;
      case "关联规则分析":
        this.$set(this.config, "支持度", 20);
        this.$set(this.config, "置信度", 90);
        this.$set(this.config, "Y变量", {});
        this.$set(this.config, "X变量", [{}]);
        this.fetchGuanlianguizefenxiZhibiao();
        break;
      case "差异性分析":
        this.$set(this.config, "样本方法", "独立样本");
        this.$set(this.config, "分组变量", {});
        this.$set(this.config, "分析变量", {});
        this.$set(this.config, "校验水准", 0.05);
        this.fetchChayijianyanduliyangbenZhibiao();
        break;
    }
  }
  /**
   * @description 执行初始化
   */
  private mounted() {
    this.init();
  }
}
