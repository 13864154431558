




























































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class MiaoshuFenzuFenlei extends Vue {
  @Prop()
  private report: any;
  private reportChange() {
    this.$emit("update:report", this.report);
  }
}
