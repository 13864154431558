



























































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class MiaoshuFenzuFenlei extends Vue {
  @Prop()
  private report: any;
  private reportChange() {
    this.$emit("update:report", this.report);
  }
  /**
   * @description 控制表头的class名称
   */
  private headerCellClassName({ columnIndex }: any) {
    if (columnIndex === 0) {
      return "blueCell";
    } else {
      return "grayCell";
    }
  }
  /**
   * @description 控制单元格的class名称
   */
  private cellClassName({ rowIndex, columnIndex }: any) {
    if (rowIndex % 4 === 0) {
      if (columnIndex === 0) {
        return "blueCell";
      }
    }
    return "whiteCell";
  }
  /**
   * @description 基本分布表合并单元格
   */
  private jibenSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    if (rowIndex % 4 === 0) {
      if (columnIndex === 0) {
        return [4, 1];
      }
    } else {
      if (columnIndex === 0) {
        return [0, 0];
      }
    }
  }
}
